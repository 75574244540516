import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { setDialer } from '../../../../redux/slices/dialerSlice';
import OpAssignedDropdown from './TabLead/OpAssignedDropdown';
import SourcesDropdown from './TabLead/SourcesDropdown';
import HistoryLeadTable from './TabLead/HistoryLeadTable';
import { Button } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';

const TabLead = () => {

  const dispatch = useDispatch();
  const dialer = useSelector((state) => state.dialer.data);

  return (
    <>

      <div className="row">

        <div className="col-4">

          <OpAssignedDropdown />

          <SourcesDropdown />

          {/******** DISABLED TOGGLE ************/}
          <div className='toggle-custom'>
            <FormControlLabel
              control={<Switch id="disabled" value={dialer.disabled}
                checked={dialer.disabled}
                onChange={event => dispatch(setDialer({ ...dialer, disabled: event.target.checked }))}
              />
              }
              label="disabled" labelPlacement="end" />
          </div>


          {/******** URL PATH ************/}
          <div className="col-12 mt-3 mb-3">
            {dialer.url_path &&
              <>
                <HtmlTooltip
                  placement="right"
                  title={dialer.url_path}
                >
                  <Badge color="secondary">
                    <Button
                      sx={{ mr:2 }}
                      variant="contained"
                      href={dialer.url_path.startsWith('http') ? dialer.url_path : `https://${dialer.url_path}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      URL PATH
                    </Button>
                  </Badge>
                </HtmlTooltip>
              </>
            }

            {/******** REFERER ************/}

            {dialer.referer &&
              <>
                <HtmlTooltip
                  placement="right"
                  title={dialer.referer}
                >
                  <Badge color="secondary">
                    <Button
                      variant="contained"
                      href={dialer.referer.startsWith('http') ? dialer.referer : `https://${dialer.referer}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      REFERER
                    </Button>
                  </Badge>
                </HtmlTooltip>
              </>
            }
          </div>


          {/******** NOTE DFFAULT ************/}
          <TextField
            style={{ marginTop: 15 }}
            onChange={event => dispatch(setDialer({ ...dialer, note_default: event.target.value }))}
            value={dialer.note_default || ""}
            fullWidth
            id="note_default" label="Note Default" multiline rows={8} />

        </div>


        <div className="col-4">

          {/******** CUSTOMER NAME ************/}
          <TextField
            required
            autoComplete="off"
            onChange={event => dispatch(setDialer({ ...dialer, customer_name: event.target.value }))}
            margin="normal" fullWidth label="Name/Surname" id="customer_name" value={dialer.customer_name || ""} />

          {/******** CUSTOMER PHONE ************/}
          <TextField
            required
            autoComplete="off"
            onChange={event => dispatch(setDialer({ ...dialer, customer_phone: event.target.value }))}
            margin="normal" fullWidth label="Phone" id="customer_phone" value={dialer.customer_phone || ""} />

          {/******** CUSTOMER ZIPCODE ************/}
          <TextField
            required
            autoComplete="off"
            onChange={event => dispatch(setDialer({ ...dialer, customer_zipcode: event.target.value }))}
            margin="normal" fullWidth label="Zipcode" id="customer_zipcode" value={dialer.customer_zipcode || ""} />

          {/******** CUSTOMER EMAIL ************/}
          <TextField
            autoComplete="off"
            onChange={event => dispatch(setDialer({ ...dialer, customer_email: event.target.value }))}
            margin="normal" fullWidth label="Email" id="customer_email" value={dialer.customer_email || ""} />

          {dialer.user_answer &&
            <TextField
              autoComplete="off"
              margin="normal" fullWidth label="User Answer" id="customer_email" value={dialer.user_answer || ""} disabled />
          }

        </div>

        <div className="col-4">

          {/******** ADVERTISING IMAGE ************/}

          {(dialer.advertising_fb?.ad_id) &&
            <div className='mt-2'>
              <img style={{ 'width': 'auto' }} src={`https://www.specialistidelludito.it/storage/assets/img/advertising_fb/${dialer.advertising_fb.ad_id}.jpg`} alt={dialer.advertising_fb.ad_id} />
              <div className='mt-2 mb-2'><strong>Facebook ADS</strong> - Service: {dialer.advertising_fb.service}</div>
              <div><strong>AD ID: </strong>{dialer.adv_fb_ad_id}</div>
            </div>
          }

          {(dialer.adv_fb_ad_id && !dialer.advertising_fb) &&
            <div className='mt-2'>
              <img style={{ 'width': 'auto' }} src={`https://www.specialistidelludito.it/storage/assets/img/no-photo-2.png`} alt={dialer.adv_fb_ad_id} />
              <div className='mt-2'><strong>AD ID: </strong>{dialer.adv_fb_ad_id}</div>
            </div>
          }

        </div>
      </div >

      <div className="row mt-4">
        <HistoryLeadTable />
      </div>

    </>

  )
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 800,
    width: 400,
    overflowY: 'scroll',
    fontSize: '1em',
    fontWeight: 'normal',
    border: '1px solid #dadde9',
  },
}));

export default TabLead