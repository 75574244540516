import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import BadgeOpSettings from './BadgeOpSettings'
import BadgeStatusGroup from './BadgeStatusGroup';
import Dialer from './Dialer';
import DialerHistoryLead from './DialerHistoryLead';
import DialerList from './DialerList';
import Loading from './Loading';
import ModalLeadEdit from '../Modals/ModalLeadEdit';
import SnackBar from '../SnackBar';

//import { generateCustomerJoyToken  } from '../../redux/slices/customerJoyTokenSlice';
import { fetchDashboardOp } from '../../redux/slices/dashboardOpSlice';
import { fetchDashboardOpDetail } from '../../redux/slices/dashboardOpDetailSlice';
import { fetchDialer } from '../../redux/slices/dialerSlice';
import { fetchDistanceMatrix } from '../../redux/slices/distanceMatrixSlice';
import { fetchHistoryLead } from '../../redux/slices/historyLeadSlice';
import { fetchItalyDb } from '../../redux/slices/italyDbSlice';
import { fetchItalyDbByZipcode } from '../../redux/slices/italyDbByZipcodeSlice';
import { fetchLogActionsSearch } from '../../redux/slices/logActionsSearchSlice';
import { fetchLogNotesByLeadId } from '../../redux/slices/logNotesByLeadIdSlice';
import { fetchLogNotesSearch } from '../../redux/slices/logNotesSearchSlice';
import { fetchLogReleases } from '../../redux/slices/logReleasesSlice';
import { fetchLeadSearch } from '../../redux/slices/leadSearchSlice';
import { fetchLogNotesByCustomerPhone } from '../../redux/slices/logNotesByCustomerPhoneSlice';
import { fetchLogRecordings } from '../../redux/slices/logRecordingsSlice';
import { fetchManager, setIsHistoryLead } from '../../redux/slices/managerSlice';
import { fetchNearestShops } from '../../redux/slices/nearestShopsSlice';
import { fetchProblems } from '../../redux/slices/problemsSlice';
import { fetchRemarketing } from '../../redux/slices/remarketingSlice';
import { fetchSduAppointments } from '../../redux/slices/sduAppointmentsSlice';
import { fetchShops } from '../../redux/slices/shopSlice';
import { fetchSources } from '../../redux/slices/sourceSlice';
import { fetchStatus, getScriptText, getLastStatus } from '../../redux/slices/statusSlice';
import { fetchTransfers } from '../../redux/slices/transfersSlices';
import { fetchWorkToDo } from '../../redux/slices/workToDoSlice';

import ModalAddLead from '../Modals/ModalAddLead';
import ModalAssigns from '../Modals/ModalAssigns';
import ModalDashboard from '../Modals/ModalDashboard';
import ModalLeadsSearch from '../Modals/ModalLeadsSearch';
import ModalLogActions from '../Modals/ModalLogActions';
import ModalLogNotesSearch from '../Modals/ModalLogNotesSearch'
import ModalLogReleases from '../Modals/ModalLogReleases';
import ModalRemarketing from '../Modals/ModalRemarketing';
import ModalRemarketingByLeads from '../Modals/ModalRemarketingByLeads';
import ModalTransfers from '../Modals/ModalTransfers';
import ModalWorkToDo from '../Modals/ModalWorkToDo';

import { setCurrentModal, setCurrentTabEditLead } from '../../redux/slices/modalSlice';
import { setLoggedOpName } from '../../redux/slices/authSlice';
import { setAddNewLead } from '../../redux/slices/addNewLeadSlice';
import dayjs from 'dayjs';
import ModalLogCustomerJoy from '../Modals/ModalLogCustomerJoy';
import { fetchLogCustomerJoyUser } from '../../redux/slices/logCustomerJoyUserSlice';
import { fetchLogCustomerJoyToken } from '../../redux/slices/logCustomerJoyTokenSlice';

const Layout = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentLeadId, refreshManager, isLoading, isHistoryLead } = useSelector((state) => state.manager);
    const { currentModal, currentTabEditLead } = useSelector((state) => state.modal);
    const { isLogged, loggedEmail, loggedId } = useSelector((state) => state.auth);
    const { customer_zipcode } = useSelector((state) => state.dialer.data);
    const { opIdFrom } = useSelector((state) => state.transfers.params);

    const addressDistanceMatrix = useSelector((state) => state.distanceMatrix.addressDistanceMatrix);
    const customerJoyToken = useSelector((state) => state.customerJoyToken.data?.token);
    const dialer = useSelector((state) => state.dialer.data);
    const historyLead = useSelector((state) => state.historyLead.data);
    const logReleases = useSelector((state) => state.logReleases);
    const manager = useSelector((state) => state.manager.data);
    const openDashboardDetail = useSelector((state) => state.dashboardOpDetail.openDashboardDetail);
    const { op_name } = (manager.op_settings && manager.op_settings[loggedId - 1]) || {};
    const snackbar = useSelector((state) => state.snackbar);
    const addNewLeadParams = useSelector((state) => state.addNewLead.params);

    const refreshLogCustomerJoyUser = useSelector((state) => state.logCustomerJoyUser.refreshLogCustomerJoyUser);
    const refreshLogCustomerJoyToken = useSelector((state) => state.logCustomerJoyToken.refreshLogCustomerJoyToken);
    const refreshDashboardOp = useSelector((state) => state.dashboardOp.refreshDashboardOp);
    const refreshLeadSearch = useSelector((state) => state.leadSearch.refreshLeadSearch);
    const refreshLogNotesSearch = useSelector((state) => state.logNotesSearch.refreshLogNotesSearch);
    const refreshLogActionsSearch = useSelector((state) => state.logActionsSearch.refreshLogActionsSearch);
    const refreshRemarketing = useSelector((state) => state.remarketing.refreshRemarketing);
    const refreshSduAppointments = useSelector((state) => state.sduAppointments.refreshSduAppointments);
    const refreshWorkToDo = useSelector((state) => state.workToDo.refreshWorkToDo);
    const refreshTransfers = useSelector((state) => state.transfers.refreshTransfers);

    const appVersionLogReleases = logReleases.data[0]?.version;
    const APP_VERSION = '1.3.14';
    document.title = "Customer Care Manager v." + APP_VERSION + "";

    useEffect(() => {
        const isLocalhost = window.location.hostname === 'localhost';
        const themeColor = isLocalhost ? '#cf3600' : '#1a73e8';
        const metaTag = document.querySelector('meta[name="theme-color"]');
        if (metaTag) {
            metaTag.setAttribute('content', themeColor);
        }
    }, []);

    useEffect(() => {
        dispatch(fetchLogReleases());
        if (appVersionLogReleases && appVersionLogReleases !== APP_VERSION) {
            const snackbar = document.getElementById("snackbar_new_app_version");
            if (snackbar) {
                snackbar.style.display = "block";
            }
        }
    }, [dispatch, currentModal, refreshManager]);

    useEffect(() => {
        dispatch(setLoggedOpName(op_name));
        dispatch(setCurrentModal(""));
        dispatch(setCurrentTabEditLead(0));
        dispatch(setIsHistoryLead(false));
    }, []);

    useEffect(() => {
        dispatch(fetchManager());
        dispatch(setIsHistoryLead(false));
    }, [dispatch, refreshManager]);

    useEffect(() => {
        dispatch(fetchHistoryLead());
    }, [dispatch, snackbar.is_saved_lead_success, dialer.id]);

    useEffect(() => {
        if (dialer.shop_info?.id > 0) {
            dispatch(fetchSduAppointments());
        }
    }, [dispatch, refreshManager, dialer.shop_info?.id, refreshSduAppointments]);

    useEffect(() => {
        dispatch(fetchSources());
        dispatch(fetchStatus());
        dispatch(fetchProblems());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchDialer());
    }, [dispatch, currentLeadId]);

    useEffect(() => {
        if (customer_zipcode && customer_zipcode.length === 5) {
            dispatch(fetchNearestShops());
            dispatch(fetchItalyDbByZipcode());
        }
    }, [dispatch, customer_zipcode]);

    useEffect(() => {
        if (addressDistanceMatrix) {
            dispatch(fetchDistanceMatrix());
        }
    }, [dispatch, addressDistanceMatrix]);

    useEffect(() => {
        if (currentTabEditLead === 4) {
            dispatch(fetchLogRecordings());
        }
    }, [dispatch, currentTabEditLead]);

    useEffect(() => {
        if (currentTabEditLead === 3 || currentTabEditLead === 5) {
            dispatch(fetchLogNotesByLeadId());
            dispatch(fetchLogNotesByCustomerPhone());
        }
    }, [dispatch, currentTabEditLead]);

    useEffect(() => {
        dispatch(getScriptText());
        dispatch(getLastStatus());
    }, [dispatch, customerJoyToken, currentTabEditLead]);

    useEffect(() => {
        if (currentModal === "modalWorkToDo") {
            dispatch(fetchWorkToDo());
        }
    }, [dispatch, currentModal, refreshWorkToDo]);

    useEffect(() => {
        if (currentModal === "modalDashboardOp") {
            dispatch(fetchDashboardOp());
        }
    }, [dispatch, currentModal, refreshDashboardOp]);

    useEffect(() => {
        if (currentModal === "modalDashboardOp" && openDashboardDetail) {
            dispatch(fetchDashboardOpDetail())
        }
    }, [dispatch, openDashboardDetail]);

    useEffect(() => {
        if (currentModal === "modalLeadSearch") {
            dispatch(fetchShops())
            dispatch(fetchItalyDb())
        }
    }, [dispatch, currentModal]);

    useEffect(() => {
        if (currentModal === "modalLeadSearch" || currentModal === "modalRemarketing") {
            dispatch(fetchLogCustomerJoyToken())
            dispatch(fetchLeadSearch())
        }
    }, [dispatch, refreshLeadSearch]);

    useEffect(() => {
        if (currentModal === "modalAddLead") {
            dispatch(setAddNewLead(
                {
                    ...addNewLeadParams,
                    id: "",
                    created_at: dayjs().format('DD/MM/YYYY HH:mm'),
                    op_assigned: "",
                    shop_id: "",
                    shop_info_id: "",
                    source: "",
                    customer_name: "",
                    customer_phone: "",
                    customer_zipcode: "",
                    customer_email: "",
                    business_name: "",
                    shop_address: ""
                }
            ));
        }
    }, [dispatch, currentModal]);

    useEffect(() => {
        if (currentModal === "modalLogNotesSearch") {
            dispatch(fetchLogNotesSearch())
        }
    }, [dispatch, refreshLogNotesSearch]);

    useEffect(() => {
        if (currentModal === "modalAssigns") {
            dispatch(fetchManager());
        }
    }, [dispatch, currentModal]);

    useEffect(() => {
        if (currentModal === "modalRemarketing") {
            dispatch(fetchRemarketing());
        }
    }, [dispatch, currentModal, refreshRemarketing]);

    useEffect(() => {
        if (currentModal === "modalTransfers") {
            dispatch(fetchTransfers());
        }
    }, [dispatch, currentModal, opIdFrom, refreshTransfers]);

    useEffect(() => {
        if (currentModal === "modalLogCustomerJoy") {
            dispatch(fetchLogCustomerJoyUser())
        }
    }, [dispatch, currentModal, refreshLogCustomerJoyUser]);


    useEffect(() => {
        if (currentModal === "modalLogCustomerJoy") {
            dispatch(fetchLogCustomerJoyToken())
        }
    }, [dispatch, currentModal, refreshLogCustomerJoyToken]);


    useEffect(() => {
        if (currentModal === "modalLogActionsSearch") {
            dispatch(fetchLogActionsSearch());
        }
    }, [dispatch, currentModal, refreshLogActionsSearch]);

    useEffect(() => {
        if (currentModal === "modalLogReleases") {
            dispatch(fetchLogReleases());
        }
    }, [dispatch, currentModal]);

    useEffect(() => {
        if (loggedEmail && loggedEmail.includes('amministrazione')) {
            dispatch(setCurrentModal('modalLeadSearch'))
        }
        if (loggedEmail && loggedEmail.includes('udibox')) {
            dispatch(setCurrentModal('modalDashboardOp'))
        }
    }, [dispatch]);

    if (!isLogged) {
        navigate('/login');
    }

    if (isLoading) {
        return (
            <Loading />
        );
    }

    /* LAYOUT UDIBOX */
    if (loggedEmail && loggedEmail.includes("udibox")) {
        return (
            <ThemeProvider theme={theme}>
                <main id="content" role="main" className="main">
                    <div className="content container-fluid" style={{ marginTop: 20, display: "block" }}>
                        <div className='row'>
                            <div className='col-12'>
                                <BadgeOpSettings />
                                <BadgeStatusGroup />
                            </div>
                        </div>
                        {currentModal === "modalLeadEdit" &&
                            <ModalLeadEdit />
                        }
                        {currentModal === "modalWorkToDo" &&
                            <ModalWorkToDo />
                        }
                        {currentModal === "modalDashboardOp" &&
                            <ModalDashboard />
                        }
                        {currentModal === "modalLeadSearch" &&
                            <ModalLeadsSearch />
                        }
                        {currentModal === "modalAddLead" &&
                            <ModalAddLead />
                        }
                        {currentModal === "modalLogCustomerJoy" &&
                            <ModalLogCustomerJoy />
                        }
                        {currentModal === "modalLogNotesSearch" &&
                            <ModalLogNotesSearch />
                        }
                        {currentModal === "modalAssigns" &&
                            <ModalAssigns />
                        }
                        {currentModal === "modalRemarketing" &&
                            <ModalRemarketing />
                        }
                        {currentModal === "modalRemarketingByLeads" &&
                            <ModalRemarketingByLeads />
                        }
                        {currentModal === "modalTransfers" &&
                            <ModalTransfers />
                        }
                        {currentModal === "modalLogActionsSearch" &&
                            <ModalLogActions />
                        }
                        {currentModal === "modalLogReleases" &&
                            <ModalLogReleases />
                        }
                        <SnackBar />
                    </div>
                </main>
            </ThemeProvider>
        );
    }

    /* LAYOUT AMMINISTRAZIONE + LOGISTICA */
    if (loggedEmail && (loggedEmail.includes("amministrazione") || loggedEmail.includes("logistica"))) {
        return (
            <ThemeProvider theme={theme}>
                <main id="content" role="main" className="main">
                    <div className="content container-fluid" style={{ marginTop: 20, display: "block" }}>
                        <div className='row'>
                            <div className='col-12'>
                                <BadgeOpSettings />
                            </div>
                        </div>
                        {currentModal === "modalLeadEdit" &&
                            <ModalLeadEdit />
                        }
                        {currentModal === "modalLeadSearch" &&
                            <ModalLeadsSearch />
                        }
                        <SnackBar />
                    </div>
                </main>
            </ThemeProvider>
        );
    }

    /* LAYOUT CUSTOMER CARE */
    return (
        <ThemeProvider theme={theme}>
            <main id="content" role="main" className="main">
                <div className="content container-fluid" style={{ marginTop: 20, display: "block" }}>
                    <div className='row'>
                        <div className='col-12'>
                            <BadgeOpSettings />
                            <BadgeStatusGroup />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-2'>
                            <Dialer />
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-9 col-xl-9 mt-2'>
                            {manager && !isHistoryLead &&
                                <DialerList />
                            }

                            {historyLead && historyLead.length > 1 && isHistoryLead &&
                                <DialerHistoryLead />
                            }
                        </div>
                    </div>
                    {currentModal === "modalLeadEdit" &&
                        <ModalLeadEdit />
                    }
                    {currentModal === "modalWorkToDo" &&
                        <ModalWorkToDo />
                    }
                    {currentModal === "modalDashboardOp" &&
                        <ModalDashboard />
                    }
                    {currentModal === "modalLeadSearch" &&
                        <ModalLeadsSearch />
                    }
                    {currentModal === "modalAddLead" &&
                        <ModalAddLead />
                    }
                    {currentModal === "modalLogNotesSearch" &&
                        <ModalLogNotesSearch />
                    }
                    {currentModal === "modalAssigns" &&
                        <ModalAssigns />
                    }
                    {currentModal === "modalRemarketing" &&
                        <ModalRemarketing />
                    }
                    {currentModal === "modalRemarketingByLeads" &&
                        <ModalRemarketingByLeads />
                    }
                    {currentModal === "modalTransfers" &&
                        <ModalTransfers />
                    }
                    {currentModal === "modalLogCustomerJoy" &&
                        <ModalLogCustomerJoy />
                    }
                    {currentModal === "modalLogActionsSearch" &&
                        <ModalLogActions />
                    }
                    {currentModal === "modalLogReleases" &&
                        <ModalLogReleases />
                    }
                    <SnackBar />
                </div>
            </main>
        </ThemeProvider>
    )
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#1a73e8',
        },
        secondary: {
            main: '#0047a5',
        },
        green: {
            main: '#00804a',
        },
        orange: {
            main: '#d37a06',
        },
        red: {
            main: '#d32f2f',
        },
        danger: {
            main: '#ff3e1d',
        },
        danger_secondary: {
            main: '#d32f2f',
        },
    },
});

export default Layout